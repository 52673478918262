<template>
  <div>
    <b-card>
      <div>
        <div class="d-flex justify-content-between align-items-center pb-1">
          <div class="d-flex align-items-center justify-center">
            <feather-icon icon="UserIcon" size="16" />
            <h4 class="pl-1" style="padding-top: 8px">LDAP</h4>
          </div>
          <b-form-checkbox
            v-model="ldap.enabled"
            name="is-vertical-menu-collapsed"
            class="mr-0"
            switch
            inline
            @change="turnonoff"
          />
        </div>
        <div>
          <validation-observer ref="ldapValidation">
            <b-row>
              <b-col cols="6"
                ><vi-input
                  rules="required"
                  v-model="ldap.ip"
                  :label="$t('ldap_ip')"
                ></vi-input
              ></b-col>
              <b-col cols="6"
                ><vi-input
                  rules="required"
                  v-model="ldap.dn"
                  :label="$t('ldap_admin_dn')"
                ></vi-input
              ></b-col>
            </b-row>
            <b-row>
              <b-col cols="6"
                ><vi-input
                  rules="required"
                  v-model="ldap.username"
                  :label="$t('username')"
                ></vi-input
              ></b-col>
              <b-col cols="6"
                ><vi-input
                  rules="required"
                  v-model="ldap.password"
                  :label="$t('password')"
                ></vi-input
              ></b-col>
            </b-row>
            <b-row>
              <b-col cols="6"
                ><vi-input
                  rules="required"
                  v-model="ldap.user_attribute"
                  :label="$t('ldap_user_attribute')"
                ></vi-input
              ></b-col>
              <b-col cols="6">
                <vi-select
                  rules="required"
                  :options="companyOptions"
                  v-model="ldap.companyId"
                  :label="$t('company')"
                ></vi-select>
              </b-col>
            </b-row>
          </validation-observer>
          <b-button @click="test" variant="outline-warning">{{
            $t('test_ldap')
          }}</b-button>
          <b-button class="ml-1" @click="confirm" variant="primary">{{
            $t('save')
          }}</b-button>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BFormInput,
  BFormGroup,
  BCol,
  BRow,
  BButton,
  BFormCheckbox,
} from 'bootstrap-vue';
import ViInput from '@/views/components/ViInput.vue';
import Permissions from './Permissions.vue';
import ViSelect from '../../components/ViSelect.vue';
import { ValidationObserver } from 'vee-validate';
import { onMounted, watch, ref } from '@vue/composition-api';
import store from '@/store';
import { dangerToast, successToast } from '@/utils/toast';
import i18n from '@/libs/i18n';
import { useToast } from 'vue-toastification/composition';

export default {
  components: {
    BCard,
    BFormInput,
    BFormGroup,
    ViInput,
    BRow,
    BCol,
    BButton,
    Permissions,
    ViSelect,
    ValidationObserver,
    BFormCheckbox,
  },
  setup(props, context) {
    const toastInstance = useToast();

    const ldapValidation = ref(null);

    const ldap = ref({
      ip: null,
      dn: null,
      username: null,
      password: null,
      user_attribute: null,
      enabled: false,
      companyId: null,
    });

    const companyOptions = ref([]);

    onMounted(async () => {
      const response = await store.dispatch('ldap/GET_LDAP', 1);
      ldap.value = response.data;

      const responseCompany = await store.dispatch('companies/GET_COMPANIES');
      if (responseCompany) {
        companyOptions.value = responseCompany.data.map((c) => {
          return { code: c.id, label: c.name };
        });
      }
    });

    const confirm = async () => {
      const validation = await ldapValidation.value.validate();
      if (validation) {
        const response = await store.dispatch('ldap/CREATE_LDAP', {
          ldapId: 1,
          ldap: ldap.value,
        });
        if (response) {
          successToast(
            toastInstance,
            i18n.t('success'),
            i18n.t('ldap_updated'),
          );
        } else {
          dangerToast(
            toastInstance,
            i18n.t('failed'),
            i18n.t('ldap_update_failed'),
          );
        }
      }
    };

    const turnonoff = async () => {
      try {
        const response = await store.dispatch('ldap/CHANGE_STATE', {
          ldapId: 1,
          enabled: ldap.value.enabled,
        });
      } catch (e) {
        dangerToast(
          toastInstance,
          i18n.t('failed'),
          i18n.t('ldap_update_failed'),
        );
      }
    };

    const test = async () => {
      try {
        const response = await store.dispatch('ldap/TEST_LDAP', {
          ldap: ldap.value,
        });
        successToast(toastInstance, i18n.t('success'), i18n.t('ldap_success'));
      } catch (e) {
        dangerToast(
          toastInstance,
          i18n.t('failed'),
          i18n.t(e.response.data.message),
        );
      }
    };

    return { confirm, ldapValidation, ldap, turnonoff, test, companyOptions };
  },
};
</script>

<style>
.disable-ldap {
  pointer-events: none;

  /* for "disabled" effect */
  opacity: 0.5;
  background: #ccc;
}
</style>
